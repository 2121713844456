import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import { Heading, Link, Text } from '@primer/react';
import ComponentLayout from '~/src/layouts/component-layout';
import { AccessibilityLink } from '~/src/components/accessibility-link';
import { Link as GatsbyLink } from 'gatsby';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Note = makeShortcode("Note");
const layoutProps = {
  _frontmatter
};
const MDXLayout = ComponentLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <Note variant="warning" mdxType="Note">
  <Text sx={{
        display: 'block',
        fontWeight: 'bold',
        mb: 2
      }} mdxType="Text">Usage for this component is not encouraged</Text>
  <Text mdxType="Text">Instead, please see <Link as={GatsbyLink} to="/components/banner" mdxType="Link">banner</Link>.</Text>
    </Note>
    <img width="960" alt="Default, warning, critical, and success flash banners" src="https://user-images.githubusercontent.com/586552/217706820-5e289d3d-40b4-4b05-b1ea-24d140d31d12.png" />
    <h2>{`Usage`}</h2>
    <p>{`Flash alerts are used to display updates or alerts pertaining to any part of the system; this includes information regarding the user's account, the organization, the repo, and more.`}</p>
    <p>{`These updates are typically not user-initiated but rather alerts that require the user's attention. Similarly to toasts, flash alerts can convey a warning, error, success, or a neutral message. If additional context is needed, provide a link for the user to learn more.`}</p>
    <h3>{`Best practices`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Use flash alerts sparingly and only when necessary. Flash alerts can disrupt the user experience and should only be used when and where relevant.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Though flash alerts don't need to be as concise as a typical Toast message, they should `}<strong parentName="p">{`be direct and not exceed two to three sentences`}</strong>{`. Don't use headings or multiple type sizes in flash alerts, rely on the default paragraph size instead.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Don't show more than one flash alert at a time. For more information, see this article on `}<a parentName="p" {...{
            "href": "https://www.nngroup.com/videos/alert-fatigue-user-interfaces/"
          }}>{`alert fatigue`}</a>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`They do not typically timeout on their own, but can include a dismiss action for messages that can be closed.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Don't rely on just color to convey the meaning of the flash alert. You should pair a message with an icon that clearly relates to the selected variant.`}</p>
      </li>
    </ul>
    <h2>{`Options`}</h2>
    <h3>{`Types`}</h3>
    <p>{`There are four types of flash banners, each with their own styles: Default, Warning, Danger, and Success.`}</p>
    <img width="960" alt="Default, warning, critical, and success flash banners" src="https://user-images.githubusercontent.com/586552/217706819-d5d615c5-4850-41fa-b1b1-51eaff2f6dd1.png" />
    <h4>{`Full-width`}</h4>
    <p>{`Full-width can be applied when attaching a banner to a container. It attaches only to the top of a container.`}</p>
    <img width="960" alt="Full-width flash" src="https://user-images.githubusercontent.com/586552/217706818-7b3bb10f-52e0-4f24-9f2b-e4b7ea5b8087.png" />
    <h4>{`Accessories and actions`}</h4>
    <p>{`Banners contain options to add an icon, button, or dismiss button to the content of the alert.`}</p>
    <img width="960" alt="Flash with icon, button, and dismiss button." src="https://user-images.githubusercontent.com/586552/217706817-8a647058-9ab6-40d9-a5f7-289d67150add.png" />
    <h3>{`Icon usage`}</h3>
    <p>{`Icons can help convey what the flash alert is for visually. It should be used alongside a message that further explains what the flash alert is aiming to indicate. You should never rely on just the color of the flash alert to convey a status, as this becomes a `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/WCAG22/#use-of-color"
      }}>{`WCAG 1.4.1 Use of Color`}</a>{` issue.`}</p>
    <h2>{`Accessibility`}</h2>
    <p>{`The flash component should trigger notifications for users utilizing assistive technology. They should be considered as part of the overall messaging in the product and do not recieve focus (therefore, they do not `}<em parentName="p">{`require`}</em>{` a dismiss button).`}</p>
    <h3>{`Known accessibility issues (GitHub staff only)`}</h3>
 <AccessibilityLink label="Flash" mdxType="AccessibilityLink" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      